<template>
  <div class="personal">
    <div class="hr"></div>
    <div class="navtiveList centerWidth marginAuto flex alignCenter">
      首页 > 个人中心 > {{showTitle}}
    </div>
    <div class="personal_bottom flex spacebetween centerWidth marginAuto">
      <div class="personal_bottom_left">
        <personal-bottom></personal-bottom>
      </div>
      <div class="personal_bottom_right"
           :style='showTitle == "会议订单" || showTitle == "酒店订单" ? "background: #fff;":""'>
        <div class="rightTitle c33 fontW flex spacebetween alignCenter">
          <div class="flex alignCenter">
            <span v-show="showTitle != '排行榜'">{{showTitle}}</span>
            <span style="cursor: pointer;"
                  v-show="showTitle == '排行榜'"
                  :class="UserRankList == 1 ? 'BigT' : 'SmallT'"
                  @click="UserRankList = 1; goother({path:'/personal/ranklist', query:{showType: 1}})">个人排行榜</span>
            <span style="margin-left: 20px;cursor: pointer;"
                  v-show="showTitle == '排行榜'"
                  :class="UserRankList == 2 ? 'BigT' : 'SmallT'"
                  @click="UserRankList = 2; goother({path:'/personal/ranklist', query:{showType: 2}})">机构排行榜</span>
            <div v-if="user.is_talents == 1 && showTitle=='助力培优'"
                 style="width:28px;height:28px;background:#10C45C;border-radius:50px;margin-left:43px;text-align:center;line-height:28px;margin-right:15px;">
              <i class="el-icon-check"
                 style="color:#fff;"></i>
            </div>
            <span v-if="user.is_talents == 1 && showTitle=='助力培优'"
                  style="color:#2C2C2C;font-size:14px;">审核成功</span>
          </div>
          <span v-if="showTitle=='申请成为讲师' && user.lecturer_status == 0"
                style="color:#ccc">审核中</span>
          <span v-if="showTitle=='申请成为讲师' && user.lecturer_status == 1"
                style="color:#47D7E3">审核通过</span>
          <span v-if="showTitle=='申请成为讲师' && user.lecturer_status == 2"
                style="color:#f00">审核驳回</span>
          <button v-if="showTitle=='助力培优'"
                  @click="postTalents"
                  :class="user.is_talents == 1 ? 'talents' : 'notalents'"
                  class="talent flex alignCenter spacearound cursorP">申请成为助力培优</button>
          <button v-if="showTitle=='地址管理'"
                  @click="$store.commit('user/SET_ADDRESSHIDDEN', true)"
                  :class="user.is_talents == 1 ? 'talents' : 'notalents'"
                  class="talent flex alignCenter spacearound cursorP"><span><i class="el-icon-plus"
                 style="padding-right:5px;"></i> 添加新地址</span></button>
          <button v-if="showTitle=='成员管理'"
                  @click="invite"
                  class="talent flex alignCenter spacearound cursorP notalents">邀请成员</button>
          <button v-if="showTitle=='邀请记录'"
                  @click="yaoqingchengyuan"
                  class="talent flex alignCenter spacearound cursorP notalents">邀请成员</button>
        </div>
        <router-view></router-view>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible"
               width="456px">
      <div class="inviteBox">
        <div class="inviteBoxTitle c33">
          扫码快速加入机构
        </div>
        <img :src="inviteImg"
             alt="">
        <div class="downInviteBoxTitle flex alignCenter"
             @click="downImg">
          <i class="el-icon-download"
             style="padding-right:8px;"></i>下载此二维码
        </div>
        <button class="closeInviteBox"
                @click="dialogVisible = false">关闭</button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleyaoqing"
               width="456px">
      <div class="inviteBox flex flexColumn alignCenter">
        <div class="inviteBoxTitle c33">
          邀请好友
        </div>
        <img :src="inviteImg"
             alt="">
        <div class="downInviteBoxTitle flex alignCenter"
             @click="downImg">
          <i class="el-icon-download"
             style="padding-right:8px;"></i>下载此二维码
        </div>
        <button class="closeInviteBox"
                @click="dialogVisibleyaoqing = false">关闭</button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { talents_apply } from '@/utils/Api/userList'
import { scerweima, organ_perfect } from '@/utils/Api/commonList'
import personalBottom from '@/components/personalBottom'
export default {
  name: 'personal',
  components: { personalBottom },
  data () {
    return {
      inviteImg: '',
      dialogVisibleyaoqing: false,
      showTitle: '',
      dialogVisible: false,
      inviteImg: '',
      UserRankList: 1,				// 排行榜 1 个人 2 机构
    }
  },
  created () {
    this.showTitle = this.$route.meta.title
  },
  methods: {
    /* 邀请好友 */
    yaoqingchengyuan () {
      scerweima({
        url: `/pages/entry/home`,
        invite_code: this.user.code,
        organ_id: this.user.id
      }).then(res => {
        this.inviteImg = res
        console.log(res)
      })
      this.dialogVisibleyaoqing = true
    },
    downImg () {
      window.open(this.inviteImg)
    },
    invite () {
      scerweima({
        url: `/pages/my/join_mechanism?name=${this.user.hospital.full_name}`,
        invite_code: this.user.code,
        organ_id: this.user.id
      }).then(res => {
        this.inviteImg = res
        console.log(res)
      })
      this.dialogVisible = true
    },
    async postTalents () {


      if (this.user.is_talents != 1) {

        organ_perfect().then(res => {
          if (res.status != 3) {
            talents_apply().then(cbk => {
              if (cbk.length < 1) {
                this.$message.success('已成功申请助力培优，请等待！')
              } else {
                this.$message.error('助力培优申请失败，请重试！')
              }
            })
            return
          }
          this.$message.warning('请到个人中心填写所有信息！')
        })
        return
      }
      this.$message.warning('已是助力培优，请勿再次申请！')
    }
  },
  watch: {
    $route () {
      if (this.$route.fullPath.split('/').includes('personal')) {
        this.showTitle = this.$route.meta.title
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.personal
    }
  }
}
</script>
<style lang="scss" scoped>
.SmallT {
  font-weight: normal;
  color: #333333;
  font-size: 14px;
}
.inviteBox {
  width: 456px;
  height: 350px;
  overflow: hidden;
  margin-left: -20px;
  .closeInviteBox {
    width: 79px;
    height: 33px;
    opacity: 1;
    background: #47d7e3;
    border-radius: 2px;
    color: #ffffff;
    font-size: 15px;
    margin-top: 15px;
    cursor: pointer;
  }
  .downInviteBoxTitle {
    text-align: center;
    font-size: 13px;
    color: #47d7e3;
    width: 100px;
    margin: auto;
    margin-top: 11px;
    cursor: pointer;
  }
  .inviteBoxTitle {
    font-size: 20px;
    margin-top: 36px;
  }
  img {
    margin: auto;
    margin-top: 25px;
    width: 158px;
    height: 158px;
  }
}
.talent {
  width: 144px;
  height: 45px;
  border-radius: 1px;
}
.talents {
  color: #b9b9b9;
  font-size: 16px;
  background: #e0e0e0;
}
.notalents {
  background: #47d7e3;
  color: #fff;
}
.personal {
  .navtiveList {
    text-align: left;
    height: 53px;
  }
  .hr {
    width: 100%;
    height: 3px;
    background: #47d7e3;
  }
}
.personal_bottom {
  min-height: 942px;
  .personal_bottom_left {
    width: 210px;
    border: 1px solid #ededed;
  }
  .personal_bottom_right {
    width: 945px;
    border: 1px solid #ededed;
    .rightTitle {
      height: 80px;
      line-height: 80px;
      text-align: left;
      width: 94%;
      margin: auto;
      border-bottom: 1px solid #f2f2f2;
      padding-left: 12px;
      position: relative;
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 2px;
        height: 18px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #47d7e3;
      }
    }
  }
}
</style>