<template>
  <div class="personalBottom">
    <div class="personInfo flex alignCenter justaround"
         v-if="$store.getters.token">
      <dl class="flex  ">
        <dt class="">
          <img :src="personal.portrait"
               style="border-radius:50px;"
               alt="">
          <div class="level"
               :style="{background: `url(${personal.icon})`}"
               style="padding-left: 10px;">{{personal.medal_name}}
            <!-- <span style="display:inline-block;width:2px;"></span> -->
          </div>
        </dt>
        <dd class="flex flexColumn spacebetween ">
          <span class="c33 fontW moreHidden">{{personal.capacity == 3 ? personal.hospital.full_name : personal.real_name}}</span>
          <div class=" flex alignCenter cursorP"
               @click="$store.dispatch('user/mysign')">
            <img src="@/assets/img/header/signIcon.png"
                 alt="">
            签到
          </div>
        </dd>
      </dl>
    </div>
    <div v-show="$store.getters.token || im.title=='关于我们'"
         @click="toPath = im.path"
         :class="toPath == im.path ? 'borderLeft' : ''"
         class="navList flex spacebetween alignCenter cursorP c33 fontW"
         v-for="(im,ix) in navlist"
         :key="ix">
      <span></span>
      <span>{{im.title}}</span>
      <i class="el-icon-arrow-right"
         style="font-size:9px;padding-right:13px;"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "personalBottom",
  data () {
    return {
      // personal:{},
      toPath: '/personal/myinfo',
      navlistTeacher: [
        {
          title: '我的课程',
          path: '/personal/mycourse'
        },
        {
          title: '数据统计',
          path: '/personal/datastatis'
        },
      ],
      navlist: [
        {
          title: '个人信息',
          path: '/personal/myinfo'
        },
        {
          title: '勋章管理',
          path: '/personal/medal'
        },
        {
          title: '积分管理',
          path: '/personal/integral'
        },
        {
          title: '学习记录',
          path: '/personal/studyrecord'
        },
        {
          title: '邀请记录',
          path: '/personal/invitedrecord'
        },
        {
          title: '成为讲师',
          path: '/personal/becometeacher'
        },
        {
          title: '排行榜',
          path: '/personal/ranklist?showType=' + 1
        },
        {
          title: '收货地址',
          path: '/personal/myaddress'
        },
        {
          title: '我的订单',
          path: '/personal/orderlist'
        },
        {
          title: '会议订单',
          path: '/personal/meetingOrder'
        },
        {
          title: '我的消息',
          path: '/personal/message'
        },
        {
          title: '推荐讲师',
          path: '/personal/recomteacher'
        },
        {
          title: '帮助中心',
          path: '/personal/helpercenter'
        },
        {
          title: '客服中心',
          path: '/personal/servicecenter'
        },
        {
          title: '关于我们',
          path: '/personal/aboutours'
        },
        {
          title: '重置密码',
          path: '/personal/setpassword'
        }
      ],
      navListOrg: [
        {
          title: '助力培优',
          path: '/personal/talentsapply'
        },
        {
          title: '成员管理',
          path: '/personal/member'
        },
        {
          title: '数据统计',
          path: '/personal/orgdatastatis'
        },
        {
          title: '学习任务',
          path: '/personal/studytask'
        },
        /* {
            title:'组织架构',
            path:'/personal/orgchart'
        }, */
        /* （new） */
        {
          title: '组织架构',
          path: '/personal/orgchartNew'
        }
      ]
    }
  },
  created () {
    this.toPath = this.$route.path
  },
  watch: {
    toPath () {
      this.goother({ path: this.toPath })
    }
  },
  computed: {
    personal () {
      if (this.$store.getters.personal.capacity == 2 && this.navlist[1].title != '我的课程') {
        this.navlist.splice(5, 1)
        this.navlist.splice(1, 0, ...this.navlistTeacher)
      }
      if (this.$store.getters.personal.capacity == 3 && this.navlist[1].title != '助力培优') {
        this.navlist.splice(5, 1)
        this.navlist.splice(1, 0, ...this.navListOrg)
      }
      if (this.$store.getters.personal.organ_id) {
        let a = true
        this.navlist.forEach((im, ix) => {
          if (im.title == '我的机构') {
            a = false
          }
        })
        if (a) {
          this.navlist.splice(1, 0, {
            title: '我的机构',
            path: '/personal/myorginfo'
          })
        }
      }
      if (this.$store.getters.personal.is_respon == 1) {
        let b = true
        this.navlist.forEach((im, ix) => {
          if (im.title == '派发学习任务') {
            b = false
          }
        })
        if (b) {
          this.navlist.splice(2, 0, {
            title: '派发学习任务',
            path: '/personal/myorgstudy'
          })
        }
      }
      return this.$store.getters.personal
    }
  }
}
</script>
<style lang="scss" scoped>
.personalBottom {
  .borderLeft {
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: #47d7e3;
  }
  .navList {
    height: 53px;
    border-bottom: 1px solid #f1f1f1;
    transition: all 0.2s;
    &:hover {
      color: #47d7e3 !important;
    }
  }
  .personInfo {
    height: 108px;
    background: #f8f8f8;
    dl {
      margin-left: 13px;
      height: 58px;
      dd {
        font-size: 13px;
        color: #777777;
        text-align: left;
        img {
          width: 16px;
          height: 16px;
          margin-right: 7px;
        }
      }
      dt {
        width: 56px;
        height: 56px;
        position: relative;
        margin-right: 13px;
        .level {
          width: 60px;
          height: 20px;
          position: absolute;
          bottom: 0;
          left: -10px;
          right: 0;
          margin: auto;
          z-index: 9;
          background-size: 100% 100% !important;
          // background-size: cover!important;
          color: #ffffff;
          font-size: 10px;
          text-align: center;
          line-height: 25px;
        }
        img {
          width: 49px;
          height: 49px;
        }
      }
    }
  }
}
</style>